const opts = [
  {id: "neg", label: "Negative"}
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Politeform {
	static get opts() {
		return opts;
	}
	static getAnswer = (word, type, opts) => {
		var endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};
		var stem = /(.*)(?!$)/.exec(word)[0];
		var ending = "";
    if (opts.neg) {ending = "ません";}
    else {ending = "ます"}

		if(type === "u") {
			var lastchar = /(.$)/.exec(word)[0];
      return stem + endings[lastchar] + ending;
		}
		else {
			if(type === "irr") {stem = irr[word];}
			return stem + ending;
		}
	}
}

export default Politeform;
