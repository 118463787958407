const opts = [
  {id: "causative", label: "Causative"},
  {id: "conditional", label: "Conditional"},
  {id: "imperative", label: "Imperative"},
  {id: "negative", label: "Negative"},
  {id: "passive", label: "Passive"},
  {id: "past", label: "Past"},
  {id: "polite", label: "Polite"},
  {id: "potential", label: "Potential"},
  {id: "provisional", label: "Provisional"},
  {id: "te", label: "て"},
  {id: "volitional", label: "Volitional"},
]

const optLabels = {
  "causative": "Causative",
  "conditional": "Conditional",
  "imperative": "Imperative",
  "negative": "Negative",
  "passive": "Passive",
  "past": "Past",
  "polite": "Polite",
  "potential": "Potential",
  "provisional": "Provisional",
  "te": "て",
  "volitional": "Volitional",
}

const func = {
  "causative": require("./causativeform").default,
  "conditional": require("./conditionalform").default,
  "imperative": require("./imperativeform").default,
  "negative": require("./negativeform").default,
  "passive": require("./passiveform").default,
  "past": require("./pastform").default,
  "polite": require("./politeform").default,
  "potential": require("./potentialform").default,
  "provisional": require("./provisionalform").default,
  "te": require("./teform").default,
  "volitional": require("./volitionalform").default,
}

export class Randomize {
  constructor() {
    this.formLabel = "";
    this.questionConj = "";
  }
	static get opts() {
    return opts;
  }
  getForm() {
    return this.formLabel
  }
  getQuestion(word, type, conjOpts) {
    var activeOpts = Object.keys(conjOpts).filter(function(key) {return conjOpts[key]})
    if (activeOpts.length === 0) {activeOpts = Object.keys(conjOpts)}
    activeOpts.push("plain");
    this.questionConj = activeOpts[Math.floor(Math.random()*activeOpts.length)];
    if (this.questionConj === "plain" || activeOpts.length <= 2) {
      this.questionConj = "";
      return word;
    } else {
      var answerOpts = {}
      func[this.questionConj].opts.forEach(function(o) {
        answerOpts[o.id] = Math.random() >= 0.5;
      });
      var question = func[this.questionConj].getAnswer(word, type, answerOpts);
      question = Array.isArray(question) ? question[0] : question;
      return question;
    }
  }
	getAnswer(word, type, conjOpts) {
      var activeOpts = Object.keys(conjOpts).filter(function(key) {return conjOpts[key]})
      if (activeOpts.length === 0) {activeOpts = Object.keys(conjOpts)}
      activeOpts = activeOpts.filter(e => e !== this.questionConj)
      this.questionConj = "";
      var conj = activeOpts[Math.floor(Math.random()*activeOpts.length)];
      var answerOpts = {}
      let labels = [];
      func[conj].opts.forEach(function(o) {
        answerOpts[o.id] = Math.random() >= 0.5;
        if (answerOpts[o.id]) {labels.push(o.label);}
      });
      var form = ""
      if (labels.length > 0) {form = labels.join(" ")}

      this.formLabel = form + " " + optLabels[conj]
      return {
        "kana": func[conj].getAnswer(word.kana, type, answerOpts),
        "kanji": func[conj].getAnswer(word.kanji, type, answerOpts),
      }
	}
}

export default Randomize;
