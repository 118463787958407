import React from "react"
import PropTypes from "prop-types"
import Conjugation from './conjugation'
import SEO from "../seo"
const verbs = require("../japanese/data/verbs.json")

const VerbConjugation = (props) => {
  const VerbForm = require(`../japanese/verbs/${props.pageContext.jsname}.js`).default;

  var getQuestion = VerbForm.getQuestion;
  var getAnswer = VerbForm.getAnswer;
  var getForm;
  var randomize;

  if (!Object.prototype.hasOwnProperty.call(VerbForm, 'getAnswer')) {
    var verbform = new VerbForm();
    getAnswer = verbform.getAnswer.bind(verbform);
    getForm = verbform.getForm.bind(verbform)
    randomize = true;
    if (!Object.prototype.hasOwnProperty.call(VerbForm, 'getQuestion')) {
      if(verbform.getQuestion) {
        getQuestion = verbform.getQuestion.bind(verbform);
      }
    }
  }

  const title = props.pageContext.title + " Practice";
  return (
    <>
      <SEO 
        title={title}
        description={`${props.pageContext.title} Japanese Verb Conjugation practice page. Simple, interactive grammar studying page with adjustable settings.`}
      />
      <Conjugation title={title} opts={VerbForm.opts} exceptions={VerbForm.exceptions ? VerbForm.exceptions : []} getQuestion={getQuestion} getAnswer={getAnswer} getForm={getForm} helplink={props.pageContext.helplink} wordData={verbs} randomize={randomize} types={{"u":"う-Verb (Godan)", "ru":"る-Verb (Ichidan)", "irr":"Irregular Verb"}} />
    </>
  )
}

VerbConjugation.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    jsname: PropTypes.string.isRequired,
    helplink: PropTypes.string
  }),
}

export default VerbConjugation
