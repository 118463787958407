const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Provisionalform {
	static get opts() {
    return opts;
  }
	static getAnswer = (word, type, opts) => {
		var stem = /(.*)(?!$)/.exec(word)[0];
		var lastchar = /(.$)/.exec(word)[0];
		var ending = ""
		var endings;

		if (opts.polite && opts.neg) {ending = "ませんなら"}
		else if (opts.polite) {ending = "ますなら"}
		else if (opts.neg) {
			ending = "なければ"
			endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};
		}
		else {
			if (type === "u") {ending = "ば";}
			else {ending = "れば";}
			endings = {く:"け", す:"せ", う:"え", ぐ:"げ", ぶ:"べ", つ:"て", む:"め", ぬ:"ね", る:"れ"};
		}

		if (opts.polite) {endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};}

		if (type === "u") {return stem + endings[lastchar] + ending;}
		else if (type === "ru") {return stem + ending;}
		else {
			if (opts.neg && !opts.polite && ["くる", "来<rt>く</rt>る"].includes(word)) {return "こなければ";}
      if (opts.neg && !opts.polite && ["つれてくる", "連<rt>つ</rt>れて来<rt>く</rt>る"].includes(word)) {return "つれてこなければ";}
			else if (opts.polite || opts.neg){
				return irr[word] + ending;
			}
			else {return stem + ending}
		}
	}
}

export default Provisionalform;
