const opts = [
  {id: "polite", label: "Polite"}
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Volitionalform {
	static get opts() {
		return opts;
	}
	static getAnswer = (word, type, opts) => {
		var ending = "";
		var endings;
		if (opts.polite) {
			ending = "ましょう"
			endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};
		}
		else {
			ending = "う"
			endings = {く:"こ", す:"そ", う:"お", ぐ:"ご", ぶ:"ぼ", つ:"と", む:"も", ぬ:"の", る:"ろ"};
		}

		var stem = /(.*)(?!$)/.exec(word)[0];

		if(type === "u") {
			var lastchar = /(.$)/.exec(word)[0];
      return stem + endings[lastchar] + ending;
		}
		else if (type === "ru") {
			if (opts.polite) {return stem + ending}
			return stem + "よう";
		} //Ru-verbs
		else {
			stem = irr[word];
			if (!opts.polite) {
				ending = "よう"
				if (word === "くる") {stem = "こ"}
				else if (word === "来<rt>く</rt>る") {stem = "来<rt>こ</rt>"}
        else if (word === "つれてくる") {stem = "つれてこ"}
        else if (word === "連<rt>つ</rt>れて来<rt>く</rt>る") {stem = "連<rt>つ</rt>れて来<rt>こ</rt>"}
			}
			return stem + ending;
		} // Irregular
	}
}

export default Volitionalform;
