const exceptions = ["くれる", "<rb>暮</rb><rt>く</rt>れる"]
const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Imperativeform {
  static get exceptions() {
    return exceptions;
  }
	static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    if (opts.neg && !opts.polite) {return word + "な";}
    var endings = {く:"け", す:"せ", う:"え", ぐ:"げ", ぶ:"べ", つ:"て", む:"め", ぬ:"ね", る:"れ"};
    var stem = /(.*)(?!$)/.exec(word)[0];
    var ending = "";
    if (opts.polite && opts.neg) {ending = "なさるな";}
    else if (opts.polite) {ending = "なさい";}
    else {ending = "ろ"}

    if(type === "u") {
      var lastchar = /(.$)/.exec(word)[0];

      if (opts.polite) {
        endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};
        return stem + endings[lastchar] + ending;
      }

      return stem + endings[lastchar];
    }
    else if (type === "ru") {
      if (exceptions.includes(word)) {
        if (opts.polite) {return stem + "なさるな"}
        return [stem, stem + "ろ"];
      }
      return stem + ending;
    } //Ru-verbs
    else {
      stem = irr[word]
      if(!opts.polite && ["くる", "来<rt>く</rt>"].includes(word)) {return "こい"}
      if(!opts.polite && ["つれてくる", "連<rt>つ</rt>れて来<rt>く</rt>"].includes(word)) {return "つれてこい"}
      return stem + ending;
    } // Irregular
  }
}

export default Imperativeform;
