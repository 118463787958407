const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]
const irr = {
	"する":"でき",
	"為<rt>す</rt>る":"でき",
  "べんきょうする":"べんきょうでき",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>でき",
	"くる":"こられ",
	"来<rt>く</rt>る":"こられ",
  "つれてくる":"つれてこられ",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れてこられ",
}

export class Potentialform {
	static get opts() {
    return opts;
  }
	static getAnswer = (word, type, opts) => {
		var endings = {く:"け", す:"せ", う:"え", ぐ:"げ", ぶ:"べ", つ:"て", む:"め", ぬ:"ね", る:"れ"};
		var ending = "";
		if (opts.polite && opts.neg) {ending = "ません"}
		else if (opts.polite) {ending = "ます"}
		else if (opts.neg) {ending = "ない"}
		else {ending = "る"}
		var stem = /(.*)(?!$)/.exec(word)[0];
		if(type === "u") {
			var lastchar = /(.$)/.exec(word)[0];
      return stem + endings[lastchar] + ending;
		}
		else if (type === "ru") {return [(stem + "られ" + ending), (stem + "れ" + ending)];} //Ru-verbs
		else {
			return irr[word] + ending;
		} // Irregular
	}
}

export default Potentialform;
