const exceptions = ["ある", "有<rt>あ</rt>る"]
const opts = [
  {id: "polite", label: "Polite"}
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Negativeform {
  static get exceptions() {
    return exceptions;
  }
	static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    var ending = "";
    var endings;
    if (opts.polite) {
      endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};
      ending = "ません"
    } else {
      endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};
      ending = "ない"
    }
    var stem = /(.*)(?!$)/.exec(word)[0];

    if(type === "u") {
      var lastchar = /(.$)/.exec(word)[0];
      if (exceptions.includes(word) && !opts.polite) {
        return "ない";
      }
      return stem + endings[lastchar] + ending;
    }
    else if (type === "ru") {
      return stem + ending;
    } //Ru-verbs
    else {
      stem = irr[word]
      if(!opts.polite) {
        if(word === "くる") {stem = "こ"}
        else if(word === "来<rt>く</rt>る"){stem = "来<rt>こ</rt>"}
        else if (word === "つれてくる") {stem = "つれてこ"}
        else if (word === "連<rt>つ</rt>れて来<rt>く</rt>る") {stem = "連<rt>つ</rt>れて来<rt>こ</rt>"}
      }
      return stem + ending;
    }
  }
}

export default Negativeform;
