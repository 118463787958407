const opts = [
  {id: "short", label: "Short"},
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
  {id: "passive", label: "Passive"},
]
const irr = {
	"する":"さ",
	"為<rt>す</rt>る":"為<rt>さ</rt>",
  "べんきょうする":"べんきょうさ",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>さ",
	"くる":"こさ",
	"来<rt>く</rt>る":"来<rt>こさ</rt>",
  "つれてくる":"つれてこさ",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>こさ</rt>",
}

export class Causativeform {
	static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    var endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};
    var stem = /(.*)(?!$)/.exec(word)[0];
    if(type === "u") {
      var lastchar = /(.$)/.exec(word)[0];
      var presuff = opts.short ? "し" : "せ";
      if (opts.passive) {presuff = "せられ";}
      if (opts.passive && opts.short) {
        presuff = "され"
        if(lastchar === "す") {return ""}
      }
      var short_end = opts.passive ? "される" : "す";
      var short_neg_end = opts.passive ? "されない" : "さない";

      var answer = "";
      if (opts.short && opts.polite && opts.neg) {answer = stem + endings[lastchar] + presuff + "ません";}
      else if (opts.short && opts.polite) {answer = stem + endings[lastchar] + presuff + "ます";}
      else if (opts.polite && opts.neg) {answer = stem + endings[lastchar] + presuff + "ません";}
      else if (opts.short && opts.neg) {answer = stem + endings[lastchar] + short_neg_end;}
      else if (opts.short) {answer = stem + endings[lastchar] + short_end;}
      else if (opts.polite) {answer = stem + endings[lastchar] + presuff + "ます";}
      else if (opts.neg) {answer = stem + endings[lastchar] + presuff + "ない";}
      else {answer = stem + endings[lastchar] + presuff + "る";}

      return answer;
    }
    else if (type === "ru") {
      if (opts.passive && opts.short) {
        return ""
      }
      var root = stem + "さ";
      if(opts.passive){
        if (opts.polite && opts.neg) {return root + "せられません";}
        else if (opts.polite) {return root + "せられます";}
        else if (opts.neg) {return root + "せられない";}
        else {return root + "せられる";}
      }
      if (opts.short && opts.polite && opts.neg) {return root + "しません";}
      else if (opts.short && opts.polite) {return root + "します";}
      else if (opts.polite && opts.neg) {return root + "せません";}
      else if (opts.short && opts.neg) {return root + "さない";}
      else if (opts.short) {return root + "す";}
      else if (opts.polite) {return root + "せます";}
      else if (opts.neg) {return root + "せない";}
      else {return root + "せる";}
    }
    else {
      if (opts.passive && opts.short) {
        return ""
      }
      var irrending = "せる";

      if (opts.short && opts.polite && opts.neg) {irrending = "しません";}
      else if (opts.short && opts.polite) {irrending = "します";}
      else if (opts.polite && opts.neg) {irrending = "せません";}
      else if (opts.short && opts.neg) {irrending = "さない";}
      else if (opts.short) {irrending = "す";}
      else if (opts.polite) {irrending = "せます";}
      else if (opts.neg) {irrending = "せない";}

      if(opts.passive){
        if (opts.polite && opts.neg) {irrending = "せられません";}
        else if (opts.polite) {irrending = "せられます";}
        else if (opts.neg) {irrending = "せられない";}
        else {irrending = "せられる";}
      }

      if (type === "irr") {
        return irr[word] + irrending;
      }
    }
  }
}

export default Causativeform;
