const exceptions = ["いく", "もっていく", "<rb>行</rb><rt>い</rt>く", "持<rt>も</rt>って<rb>行</rb><rt>い</rt>く"]
const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Teform {
	static get exceptions() {
    return exceptions;
  }
	static get opts() {
    return opts;
  }
	static getAnswer = (word, type, opts) => {
		var stem = /(.*)(?!$)/.exec(word)[0];
		var endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};
		var pol_endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};

		if(type === "u") {
			var lastchar = /(.$)/.exec(word)[0];
      if (["ある", "有<rt>あ</rt>る"].includes(word) && opts.neg && !opts.polite) {return "なくて"}
			if (opts.neg && opts.polite) {return stem + pol_endings[lastchar] + "ませんで"}
			else if (opts.neg) {
				var neg_stem = stem + endings[lastchar] + "な"
				return [neg_stem + "くて", neg_stem + "いで"]
			}
			else if (opts.polite) {return stem + pol_endings[lastchar] + "まして"}
			else {
				if (['う', 'つ', 'る'].includes(lastchar)) {return stem + "って";}
				else if (['む', 'ぶ', 'ぬ'].includes(lastchar)) {return stem + "んで";}
				else if (['く'].includes(lastchar)) {
					if (exceptions.includes(word)) {
            return stem + "って"
          }
					else {return stem + "いて";}
				}
				else if (['ぐ'].includes(lastchar)) {return stem + "いで";}
				else if (["す"].includes(lastchar)) {return stem + "して";}
				else {return "Error";}
			}
		}
		else {
			if (type === "irr") {stem = irr[word]}
      if (["くる", "来<rt>く</rt>る"].includes(word) && opts.neg && !opts.polite) {stem = "こ"}
      if (["つれてくる", "連<rt>つ</rt>れて来<rt>く</rt>る"].includes(word) && opts.neg && !opts.polite) {stem = "つれてこ"}
			if (opts.neg && opts.polite) {return stem + "ませんで";}
			else if (opts.neg) {return [stem + "なくて", stem + "ないで"]}
			else if (opts.polite) {return stem + "まして";}
			else {return stem + "て";}
		} //Ru-verbs
	}
}

export default Teform;
