const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]
const irr = {
	"する":"さ",
	"為<rt>す</rt>る":"為<rt>さ</rt>",
  "べんきょうする":"べんきょうさ",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>さ",
	"くる":"こら",
	"来<rt>く</rt>る":"来<rt>こら</rt>",
  "つれてくる":"つれてこら",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>こら</rt>",
}

export class Passiveform {
  static get opts() {
    return opts;
  }
  static getAnswer = (word, type, opts) => {
    var ending = "";
    if (opts.polite && opts.neg) {ending = "ません";}
    else if (opts.polite) {ending = "ます";}
    else if (opts.neg) {ending = "ない";}
    else {ending = "る"}
    var endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};
    var stem = /(.*)(?!$)/.exec(word)[0];
    if(type === "u") {
      var lastchar = /(.$)/.exec(word)[0];
      return stem + endings[lastchar] + "れ" + ending;
    }
    else if (type === "ru") {return stem + "られ" + ending;} //Ru-verbs
    else {
      stem = irr[word];
      return stem + "れ" + ending;
    } // Irregular
  }
}

export default Passiveform;
