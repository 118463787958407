const exceptions = ["いく", "もっていく", "<rb>行</rb><rt>い</rt>く", "持<rt>も</rt>って<rb>行</rb><rt>い</rt>く"]
const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]
const irr = {
	"する":"し",
	"為<rt>す</rt>る":"為<rt>し</rt>",
  "べんきょうする":"べんきょうし",
  "勉<rt>べん</rt>強<rt>きょう</rt>する":"勉<rt>べん</rt>強<rt>きょう</rt>し",
	"くる":"き",
	"来<rt>く</rt>る":"来<rt>き</rt>",
  "つれてくる":"つれてき",
	"連<rt>つ</rt>れて来<rt>く</rt>る":"連<rt>つ</rt>れて来<rt>き</rt>",
}

export class Pastform {
	static get exceptions() {
		return exceptions;
	}
	static get opts() {
		return opts;
	}
	static getAnswer = (word, type, opts) => {
		var endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};
		var a_endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};

		var stem = /(.*)(?!$)/.exec(word)[0];
		if(type === "u") {
      var lastchar = /(.$)/.exec(word)[0];
      var ending = endings[lastchar];
      var a_ending = a_endings[lastchar];

if (opts.polite && opts.neg) {return stem + ending + "ませんでした";}
  else if (opts.polite) {return stem + ending + "ました";}
  else if (opts.neg) {
    if (["ある", "有<rt>あ</rt>る"].includes(word)) {
      return "なかった"
    }
    return stem + a_ending + "なかった";
  }

			if (['う', 'つ', 'る'].includes(lastchar)) {return stem + "った";}
			else if (['む', 'ぶ', 'ぬ'].includes(lastchar)) {return stem + "んだ";}
			else if (['く'].includes(lastchar)) {
				if (exceptions.includes(word)) {
          return stem + "った"
        }
				else {return stem + "いた";}
			}
			else if (['ぐ'].includes(lastchar)) {return stem + "いだ";}
			else if (["す"].includes(lastchar)) {return stem + "した";}
			else {return "Error";}
		}
		else {
			if (type === "irr") {
				stem = irr[word]
				if(!opts.polite && opts.neg) {
					if(word === "くる") {stem = "こ"}
					else if (word === "来<rt>く</rt>る") {stem = "来<rt>こ</rt>"}
          else if (word === "つれてくる") {stem = "つれてこ"}
          else if (word === "連<rt>つ</rt>れて来<rt>く</rt>る") {stem = "連<rt>つ</rt>れて来<rt>こ</rt>"}
				}
			}
      if (opts.polite && opts.neg) {return stem + "ませんでした";}
      else if (opts.polite) {return stem + "ました";}
      else if (opts.neg) {return stem + "なかった";}
      return stem + "た"; //Ru-verbs
    }
	}
}

export default Pastform;
