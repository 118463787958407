const exceptions = ["いく", "もっていく", "<rb>行</rb><rt>い</rt>く", "持<rt>も</rt>って<rb>行</rb><rt>い</rt>く"]
const opts = [
  {id: "neg", label: "Negative"},
  {id: "polite", label: "Polite"},
]

export class Conditionalform {
	static get exceptions() {
    return exceptions;
  }
	static get opts() {
    return opts;
  }
	static getAnswer = (word, type, opts) => {
		var pol_endings = {く:"き", す:"し", う:"い", ぐ:"ぎ", ぶ:"び", つ:"ち", む:"み", ぬ:"に", る:"り"};
		var neg_endings = {く:"か", す:"さ", う:"わ", ぐ:"が", ぶ:"ば", つ:"た", む:"ま", ぬ:"な", る:"ら"};

		var stem = /(.*)(?!$)/.exec(word)[0];
		if(type === "u") {
			var lastchar = /(.$)/.exec(word)[0];
			if (opts.polite && opts.neg) {return stem + pol_endings[lastchar] + "ませんでしたら";}
			if (opts.neg) {return stem + neg_endings[lastchar] + "なかったら";}
			if (opts.polite) {return stem + pol_endings[lastchar] + "ましたら";}
			if (['う', 'つ', 'る'].includes(lastchar)) {return stem + "ったら";}
			else if (['む', 'ぶ', 'ぬ'].includes(lastchar)) {return stem + "んだら";}
			else if (['く'].includes(lastchar)) {
				if (exceptions.includes(word)) {
          return stem + "ったら"
        }
				else {return stem + "いたら";}
			}
			else if (['ぐ'].includes(lastchar)) {return stem + "いだら";}
			else if (["す"].includes(lastchar)) {return stem + "したら";}
			else {return "Error";}
		}
		else if (type === "ru") {
			if (opts.polite && opts.neg) {return stem + "ませんでしたら";}
			if (opts.neg) {return stem + "なかったら";}
			if (opts.polite) {return stem + "ましたら";}
			return stem + "たら";
		} //Ru-verbs
		else {
			if (opts.polite && opts.neg) {
				if (word.endsWith("する")) {return word.replace("する", "し") + "ませんでしたら";}
				else if (word.endsWith("為<rt>す</rt>る")) {return word.replace("為<rt>す</rt>る", "為<rt>し</rt>") + "ませんでしたら";}
				else if (word.endsWith("くる")) {return word.replace("くる", "き") + "ませんでしたら";}
				else if (word.endsWith("来<rt>く</rt>る")) {return word.replace("来<rt>く</rt>る", "来<rt>き</rt>") + "ませんでしたら";}
			}
			if (opts.polite) {
        if (word.endsWith("する")) {return word.replace("する", "し") + "ましたら";}
				else if (word.endsWith("為<rt>す</rt>る")) {return word.replace("為<rt>す</rt>る", "為<rt>し</rt>") + "ましたら";}
				else if (word.endsWith("くる")) {return word.replace("くる", "き") + "ましたら";}
				else if (word.endsWith("来<rt>く</rt>る")) {return word.replace("来<rt>く</rt>る", "来<rt>き</rt>") + "ましたら";}
			}
			if (opts.neg) {
        if (word.endsWith("する")) {return word.replace("する", "し") + "なかったら";}
				else if (word.endsWith("為<rt>す</rt>る")) {return word.replace("為<rt>す</rt>る", "為<rt>し</rt>") + "なかったら";}
				else if (word.endsWith("くる")) {return word.replace("くる", "こ") + "なかったら";}
				else if (word.endsWith("来<rt>く</rt>る")) {return word.replace("来<rt>く</rt>る", "来<rt>こ</rt>") + "なかったら";}
			}
      if (word.endsWith("する")) {return word.replace("する", "し") + "たら";}
      else if (word.endsWith("為<rt>す</rt>る")) {return word.replace("為<rt>す</rt>る", "為<rt>し</rt>") + "たら";}
      else if (word.endsWith("くる")) {return word.replace("くる", "き") + "たら";}
	  else if (word.endsWith("来<rt>く</rt>る")) {return word.replace("来<rt>く</rt>る", "来<rt>き</rt>") + "たら";}
		} // Irregular
	}
}

export default Conditionalform;
